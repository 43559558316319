<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="top right" />
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav -->
          <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
            <div class="wizard-steps">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg src="media/svg/icons/Media/Airplay.svg" />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Device settings</h3>
                    <div class="wizard-desc">Logger & Device Configuration</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg src="media/svg/icons/Map/Compass.svg" />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Setting the Location</h3>
                    <div class="wizard-desc">
                      Select the location of the Device
                    </div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="media/svg/icons/General/Thunder-move.svg"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Additional Device Settings</h3>
                    <div class="wizard-desc">Monthly rental cost ...</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="media/svg/icons/General/Settings-2.svg"
                      />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Device Settings Configuration</h3>
                    <div class="wizard-desc">Device configuration ...</div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg src="media/svg/icons/General/Like.svg" />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title">Entry Overview!</h3>
                    <div class="wizard-desc">Review and Submit</div>
                  </div>
                </div>
              </div>
              <div
                v-if="currentUserRole === 'ROLE_ROOT'"
                class="wizard-step"
                data-wizard-type="step"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-icon">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg src="media/svg/icons/Cooking/Grater.svg" />
                    </span>
                  </div>
                  <div class="wizard-label">
                    <h3 class="wizard-title text-danger">Secret Powers</h3>
                    <div class="wizard-desc">Root user secret powers</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body -->
          <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="offset-xxl-2 col-xxl-8">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Device settings
                  </h4>
                  <div class="form-group">
                    <label>Logger</label>
                    <vSelect
                      v-model="device.logger"
                      class="form-control form-control-solid form-control--logger-select"
                      placeholder="Odaberi Logger"
                      :options="loggers"
                      @input="onLoggerSelect"
                      :reduce="(logger) => logger['@id']"
                      label="imei"
                    />
                    <button
                      @click="deselectLogger"
                      class="btn btn-light-danger btn-block mt-3"
                    >
                      Deselect Logger
                    </button>
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="device.name"
                      class="form-control form-control-solid"
                    />
                  </div>
                  <div class="form-group" v-if="company">
                    <label>Company</label>
                    <input
                      type="text"
                      disabled="disabled"
                      class="form-control form-control-solid"
                      :value="company.name"
                      :placeholder="company.name"
                    />
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Setting the Device Location
                  </h4>
                  <div class="form-group">
                    <label>{{ $t('GENERAL.LOCATION') }}</label>
                    <vSelect
                      v-model="device.location"
                      :options="locations"
                      :reduce="(location) => location['@id']"
                      label="title"
                      class="form-control form-control-solid form-control--location-select"
                    >
                      <template #option="{ title, city }">
                        <div style="display: flex; align-items: baseline">
                          <span>{{ title }}</span>
                          <span style="margin-left: 0.5rem"
                            >- {{ city.name }}</span
                          >
                        </div>
                      </template>
                      <template #selected-option="{ title, city }">
                        <div style="display: flex; align-items: baseline">
                          <span>{{ title }}</span>
                          <span style="margin-left: 0.5rem"
                            >- {{ city.name }}</span
                          >
                        </div>
                      </template>
                    </vSelect>
                  </div>

                  <div class="form-group">
                    <label>Tags</label>
                    <vSelect
                      multiple
                      v-model="device.tags"
                      class="form-control form-control-solid form-control--location-select"
                      placeholder="Odaberite tagove"
                      :options="tags"
                      :reduce="(tag) => tag['@id']"
                      label="name"
                    />
                  </div>
                  <b-button
                    @click="showModal"
                    class="btn btn-light-primary font-weight-bold btn-block mt-4"
                    >Add Tag</b-button
                  >
                  <b-modal id="add-tag-modal" centered hide-header hide-footer>
                    <template>
                      <b-button
                        class="btn btn-light-primary mb-3"
                        block
                        @click="$bvModal.hide('add-tag-modal')"
                        >Close</b-button
                      >
                      <div class="form-group mb-2">
                        <div class="input-group">
                          <input
                            id="tagName"
                            type="text"
                            v-model="newTag"
                            placeholder="Tag name"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <button
                        @click="addTag"
                        class="btn btn-light-primary font-weight-bold btn-block mt-4"
                      >
                        Add
                      </button>
                    </template>
                  </b-modal>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Additional Device Settings
                  </h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Is Demo Device</label>
                        <VtSwitch
                          @checkedEvent="toggleIsDemo(device.isDemoDevice)"
                          :is-checked="device.isDemoDevice"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Active</label>
                        <VtSwitch
                          @checkedEvent="toggleIsActive(device.isActive)"
                          :is-checked="device.isActive"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Service mode</label>
                        <VtSwitch
                          @checkedEvent="toggleServiceMode(device.serviceMode)"
                          :is-checked="device.serviceMode"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceServiceMode"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        this.currentUserCompanyOib === '05959244936' &&
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="col-md-12"
                    >
                      <div class="form-group">
                        <label>Fiscal</label>
                        <VtSwitch
                          @checkedEvent="toggleFiscal(device.fiscal)"
                          :is-checked="device.fiscal"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceFiscal"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>The device has a "stamp"</label>
                        <VtSwitch
                          @checkedEvent="toggleHaveMark(device.haveMark)"
                          :is-checked="device.haveMark"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceHaveMark"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                    >
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Acceptor</label>
                          <VtSwitch
                            @checkedEvent="toggleIsAcceptor(device.isAcceptor)"
                            :is-checked="device.isAcceptor"
                            classes="switch-md switch-icon switch-outline switch-primary"
                            name="deviceIsAcceptor"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="device.isAcceptor">
                        <div class="form-group">
                          <label>Saldo Reducer</label>
                          <VtSwitch
                            @checkedEvent="
                              toggleIsSaldoReducer(device.isSaldoReducer)
                            "
                            :is-checked="device.isSaldoReducer"
                            classes="switch-md switch-icon switch-outline switch-primary"
                            name="deviceIsSaldoReducer"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="device.isSaldoReducer">
                        <div class="form-group">
                          <label>Saldo</label>
                          <input
                            v-model="device.saldo"
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            placeholder="Saldo"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Double SMS</label>
                        <VtSwitch
                          @checkedEvent="toggleDoubleSMS(doubleSMS)"
                          :is-checked="doubleSMS"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="doubleSMS"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) && this.currentUserRole === 'ROLE_ADMIN'
                      "
                    >
                      <div class="form-group">
                        <label>{{ $t('GENERAL.TOKEN_DROP_DEVICE') }}</label>
                        <VtSwitch
                          @checkedEvent="
                            toggleIsMonsterDevice(device.isMonsterDevice)
                          "
                          :is-checked="device.isMonsterDevice"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceIsMonsterDevice"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) && this.currentUserRole === 'ROLE_ADMIN'
                      "
                    >
                      <div class="form-group">
                        <label>Prize</label>
                        <VtSwitch
                          @checkedEvent="
                            toggleIsPrizeDevice(device.isPrizeDevice)
                          "
                          :is-checked="device.isPrizeDevice"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceIsPrizeDevice"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) &&
                        this.currentUserRole === 'ROLE_ADMIN' &&
                        device.isPrizeDevice
                      "
                    >
                      <div class="form-group">
                        <label>Šifra nagrade</label>
                        <input
                          v-model="device.prizeMeta.code"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Šifra nagrade"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) &&
                        this.currentUserRole === 'ROLE_ADMIN' &&
                        device.isPrizeDevice
                      "
                    >
                      <div class="form-group">
                        <label>Naziv nagrade</label>
                        <input
                          v-model="device.prizeMeta.name"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Naziv nagrade"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) &&
                        this.currentUserRole === 'ROLE_ADMIN' &&
                        device.isPrizeDevice
                      "
                    >
                      <div class="form-group">
                        <label>Cijena nagrade</label>
                        <input
                          v-model="device.prizeMeta.price"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Cijena nagrade"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      v-if="
                        this.currentUserPersonalInfo.email.includes(
                          '@kidsdelux.hr'
                        ) &&
                        this.currentUserRole === 'ROLE_ADMIN' &&
                        device.isPrizeDevice
                      "
                    >
                      <div class="form-group">
                        <label>Frekvencija nagrade (u eurima)</label>
                        <input
                          v-model="device.prizeMeta.frequencyInEuros"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Iznos eura za svaku dodijeljenu nagradu"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) &&
                      this.currentUserRole === 'ROLE_ADMIN' &&
                      device.isMonsterDevice
                    "
                  >
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Token</label>
                        <input
                          v-model="device.token"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Token"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Token Minimum</label>
                        <input
                          v-model="device.tokenMinimum"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Token minimum"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) && this.currentUserRole === 'ROLE_ADMIN'
                    "
                  >
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Saldo</label>
                        <input
                          v-model="device.saldo"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Saldo"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Saldo Minimum</label>
                        <input
                          v-model="device.saldoMinimum"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Saldo minimum"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>ISU number</label>
                        <input
                          v-model="device.isuNumber"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="ISU broj"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>PP Label</label>
                        <input
                          v-model="device.businessSpaceLabel"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Oznaka poslovnog prostora"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Inventory number</label>
                        <input
                          v-model="device.inventoryNumber"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="locstate"
                          placeholder="Inventurni broj"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Bill number</label>
                        <input
                          v-model.number="device.billNumber"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Bill number"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Machine Id</label>
                        <input
                          v-model="device.machineId"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Machine ID"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-8"></div>
                  <div class="row">
                    <ul>
                      <li v-for="article in device.articles" :key="article.id">
                        {{ article.name }}
                      </li>
                    </ul>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-button
                        @click="addArticles"
                        class="btn btn-light btn-text-primary btn-hover-text-primary font-weight-bold btn-block"
                        hide-footer
                        v-b-modal.modal-1
                        >Add Article</b-button
                      >

                      <b-modal id="modal-1" title="Dodaj artikl">
                        <div class="row">
                          <ul>
                            <li
                              @click="selectArticle(article)"
                              v-for="article in articles"
                              :key="article.id"
                            >
                              {{ article.name }}
                            </li>
                          </ul>
                        </div>
                      </b-modal>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 4-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Device Configuration
                  </h4>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Is Converted to Euro (€)</label>
                        <VtSwitch
                          @checkedEvent="
                            toggleIsConvertedToEuro(device.isConvertedToEuro)
                          "
                          :is-checked="device.isConvertedToEuro"
                          classes="switch-md switch-icon switch-outline switch-primary"
                          name="deviceIsConvertedToEuro"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <vue-json-editor
                          v-model="deviceConfiguration"
                          :show-btns="false"
                          mode="code"
                          :expandedOnStart="true"
                        ></vue-json-editor>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <button
                          @click="updateDeviceConfiguration"
                          class="btn btn-primary btn-block font-weight-bold"
                        >
                          Update configuration
                        </button>
                        <button
                          @click="viewDeviceConfiguration"
                          class="btn btn-light-primary btn-block"
                        >
                          View all
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 4-->

                <!--begin: Wizard Step 5-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    View all entered Device fields
                  </h4>
                  <div class="border-bottom mb-5 pb-5">
                    <div class="font-weight-bold mb-3">Device settings:</div>
                    <div class="line-height-md">
                      Logger: {{ device.logger }}
                      <br />
                      Name: {{ device.name }} <br />
                      Company: {{ device.company }}
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 5-->

                <!--begin: Wizard Step 6-->
                <div
                  v-if="currentUserRole === 'ROLE_ROOT'"
                  class="pb-5"
                  data-wizard-type="step-content"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Root User Secret Powers
                  </h4>
                  <div class="border-bottom mb-5 pb-5">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Saldo</label>
                          <input
                            v-model="device.saldo"
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            placeholder="Saldo"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label>Current Logger Company</label>
                        <input
                          v-model="device.logger.company"
                          type="text"
                          disabled
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Current Company"
                        />
                      </div>
                      <div class="col-md-12">
                        <label>Company</label>
                        <vSelect
                          v-model="device.company"
                          :options="companies"
                          :reduce="(company) => company['@id']"
                          label="id"
                          class="form-control form-control-solid form-control--location-select"
                        >
                        </vSelect>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 6-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Back
                    </button>
                  </div>
                  <div>
                    <button
                      v-on:click="submit"
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-submit"
                    >
                      {{ $t('GENERAL.SAVE') }}
                    </button>
                    <button
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </div>
              <!--end: Wizard-->
            </div>
          </div>
          <!--end: Wizard Body -->
        </div>
        <!--end: Wizard-->
      </div>
    </div>

    <div class="card card-custom">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Messages</span>
        </h3>
        <div class="card-toolbar">
          <button @click="refresh" class="btn btn-light-primary mr-2">
            <span class="svg-icon svg-icon-md mr-0">
              <inline-svg src="media/svg/icons/Media/Repeat.svg" />
            </span>
          </button>
          <a
            @click.prevent="loadMore"
            href="#"
            class="btn btn-light-primary font-weight-bolder font-size-sm"
            >Load More</a
          >
        </div>
      </div>
      <div class="card-body py-0">
        <div v-if="isMessagesLoaded" class="table-responsive">
          <table class="table table-head-custom table-vertical-center">
            <thead>
              <tr class="text-left text-uppercase">
                <th style="min-width: 120px">IMEI</th>
                <th style="min-width: 120px">Data</th>
                <th class="pr-0" style="min-width: 160px; text-align: right">
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in messages" :key="message.id">
                <td>
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    v-b-modal.message-detail
                    @click.prevent="onSelectMessage(index)"
                  >
                    {{ message.imei }}
                  </a>
                </td>
                <td>
                  <span class="label label-success label-inline">{{
                    deviceParser(message.data)
                  }}</span>
                  <!--                  <span-->
                  <!--                    v-if="deviceParser(message.data) === 'PING'"-->
                  <!--                    class="label label-danger label-inline"-->
                  <!--                    >PING</span-->
                  <!--                  >-->
                </td>
                <td class="text-right pr-0">
                  {{ message.createdAt | formatDateTime }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="col-12 text-center my-5 pt-5 pb-2">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>
    </div>

    <b-modal id="message-detail" title="Message details" hide-footer>
      <div>
        <b-button
          class="btn btn-light-primary mb-3"
          block
          @click="$bvModal.hide('message-detail')"
          >Close</b-button
        >
        <code>
          {{ selectedMessage }}
        </code>
      </div>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_configuration_modal"
          size="xl"
          hide-header
          hide-footer
        >
          <div>
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_configuration_modal')"
              >Close</b-button
            >
            <div v-for="(configuration, key) in allConfigurations" :key="key">
              <code>{{ configuration }}</code>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import vueJsonEditor from 'vue-json-editor'
import KTWizard from '@/assets/js/components/wizard'
import KTUtil from '@/assets/js/components/util'

export default {
  name: 'DevicesEdit',
  components: {
    vSelect,
    VtSwitch,
    vueJsonEditor,
  },
  filters: {
    date: dateFilter,
    json: function (value) {
      return JSON.stringify(value, null, 2)
    },
  },
  data() {
    return {
      device: {
        name: null,
        saldo: null,
        isDemoDevice: false,
        isActive: true,
        isAcceptor: false,
        isSaldoReducer: false,
        serviceMode: false,
        fiscal: true,
        haveMark: false,
        logger: null,
        isuNumber: null,
        businessSpaceLabel: null,
        inventoryNumber: null,
        location: null,
        company: null,
        tags: [],
        articles: [],
        billNumber: null,
        problematicRules: {
          hours: null,
        },
        tokenMinimum: 0,
        saldoMinimum: 0,
        token: 0,
        isConvertedToEuro: false,
        isPrizeDevice: false,
        isMonsterDevice: false,
        prizeMeta: {
          code: '',
          name: '',
          price: 0,
        },
        machineId: '',
      },
      loggers: [],
      locations: [],
      tags: [],
      articles: [],
      messages: [],
      isMessagesLoaded: false,
      companies: [],
      isLoaded: false,
      page: 1,
      selectedMessage: null,
      company: null,
      deviceConfigurationId: null,
      deviceConfiguration: {
        imei: null,
        configuration: null,
      },
      doubleSMS: false,
      newTag: '',
      allConfigurations: [],
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserPersonalInfo',
      'currentIsTopGun',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Devices', route: '/devices/list' },
      { title: 'Edit Device' },
    ])

    // get device
    ApiService.get('/devices', this.$route.params.id).then(({ data }) => {
      this.device.name = data.name
      this.device.saldo = data.saldo
      this.device.isAcceptor = data.isAcceptor
      this.device.isDemoDevice = data.isDemoDevice
      this.device.isActive = data.isActive
      this.device.isSaldoReducer = data.isSaldoReducer
      this.device.serviceMode = data.serviceMode
      this.device.fiscal = data.fiscal
      this.device.logger = data.logger
      this.device.isuNumber = data.isuNumber
      this.device.token = parseFloat(data.token)
      this.device.tokenMinimum = parseFloat(data.tokenMinimum)
      this.device.saldoMinimum = parseFloat(data.saldoMinimum)
      this.device.businessSpaceLabel = data.businessSpaceLabel
      this.device.inventoryNumber = data.inventoryNumber
      this.device.location = data.location
      this.device.company = data.company
      this.device.tags = data.tags
      this.device.articles = data.articles
      this.device.billNumber = data.billNumber
      this.device.isProblematic = data.isProblematic
      this.device.isCritical = data.isCritical
      this.device.sendProblematicEmailNotifications =
        data.sendProblematicEmailNotifications
      this.device.sendCriticalEmailNotifications =
        data.sendCriticalEmailNotifications
      this.device.isConvertedToEuro = data.isConvertedToEuro
      this.device.isPrizeDevice = data.isPrizeDevice
      this.device.isMonsterDevice = data.isMonsterDevice
      this.device.prizeMeta = data.prizeMeta || {
        code: '',
        name: '',
        price: 0,
      }
      this.device.machineId = data.machineId

      // get device configuration
      ApiService.get(
        'device_configurations',
        `?imei=${this.device.logger.imei}&order[createdAt]=desc`
      ).then(({ data }) => {
        if (data['hydra:member'].length !== 0) {
          this.deviceConfigurationId = data['hydra:member'][0].id
          this.deviceConfiguration.imei = data['hydra:member'][0].imei
          this.deviceConfiguration.configuration =
            data['hydra:member'][0].configuration

          if (
            data['hydra:member'][0].configuration.hasOwnProperty('start-game')
          ) {
            if (
              data['hydra:member'][0].configuration['start-game']
                .num_game_starts === 2
            ) {
              this.doubleSMS = true
            }
          }
        } else {
          this.deviceConfiguration.imei = this.device.logger.imei
        }

        //
        this.refresh()
      })

      if (this.currentUserRole === 'ROLE_ADMIN') {
        // get loggers
        this.fetchCompanyLoggers()
        /* ApiService.get(
          'loggers',
          `?pagination=false&company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`
        ).then(({ data }) => {
          data['hydra:member'].forEach((logger) => {
            if (!logger.haveDevice) this.loggers.push(logger)
          })
        }) */

        // locations
        ApiService.get(
          '/locations',
          '?pagination=false&company=' +
            this.currentUserCompany.replace('/api/v1/companies/', '')
        ).then((response) => {
          this.locations = [...response.data['hydra:member']]
        })

        // get tags
        ApiService.get(
          '/tags',
          '?pagination=false&company=' + this.currentUserCompany
        ).then(({ data }) => {
          this.tags = [...data['hydra:member']]
        })
      }

      if (this.currentUserRole === 'ROLE_SUPER_ADMIN') {
        // get loggers
        this.fetchAllLoggers()
        /* ApiService.get('/loggers', '?pagination=false').then(({ data }) => {
          this.loggers = [...data['hydra:member']]
        }) */

        // get locations
        ApiService.get('/locations', '?pagination=false').then(({ data }) => {
          this.locations = [...data['hydra:member']]
        })

        // get tags
        ApiService.get('/tags?pagination=false').then(({ data }) => {
          this.tags = [...data['hydra:member']]
        })
      }

      if (this.currentUserRole === 'ROLE_ROOT') {
        // get loggers
        this.fetchAllLoggers()
        ApiService.get('/loggers', '').then(({ data }) => {
          this.loggers = [...data['hydra:member']]
        })

        // get locations
        ApiService.get('/locations', '?pagination=false').then(({ data }) => {
          this.locations = [...data['hydra:member']]
        })

        // get tags
        ApiService.get('/tags?pagination=false').then(({ data }) => {
          this.tags = [...data['hydra:member']]
        })

        // get companies
        ApiService.get('/companies', '?pagination=false').then(({ data }) => {
          this.companies = [...data['hydra:member']]
        })
      }
    })

    setTimeout(() => {
      ApiService.get(
        'companies',
        this.currentUserCompany.replace('/api/v1/companies/', '')
      ).then(({ data }) => {
        this.company = data
        this.device.company = this.company['@id']

        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v2', {
          startStep: 1,
          clickableSteps: true,
        })

        // Change event
        wizard.on('change', function (/*wizardObj*/) {
          setTimeout(() => {
            KTUtil.scrollTop()
          }, 500)
        })
      })
    }, 1000)
  },
  methods: {
    //
    async fetchAllLoggers() {
      let allLoggers = []
      let currentPage = 1
      let totalPages = 1

      const fetchPage = (page) => {
        return ApiService.get(
          'loggers',
          `?select=id,haveDevice&pagination=true&itemsPerPage=100&page=${page}`
        ).then(({ data }) => {
          allLoggers = [...allLoggers, ...data['hydra:member']]
          data['hydra:member'].forEach((logger) => {
            this.loggers.push(logger)
          })
          totalPages = data['hydra:totalItems'] / 100 || 1
          return totalPages
        })
      }

      while (currentPage <= totalPages) {
        await fetchPage(currentPage)
        currentPage += 1
      }

      this.loggers = allLoggers
    },

    async fetchCompanyLoggers() {
      let allLoggers = []
      let currentPage = 1
      let totalPages = 1

      const fetchPage = (page) => {
        return ApiService.get(
          'loggers',
          `?company=${this.currentUserCompany}&select=id,haveDevice&pagination=true&itemsPerPage=100&page=${page}`
        ).then(({ data }) => {
          allLoggers = [...allLoggers, ...data['hydra:member']]
          data['hydra:member'].forEach((logger) => {
            if (!logger.haveDevice) this.loggers.push(logger)
          })
          totalPages = data['hydra:totalItems'] / 100 || 1
          return totalPages
        })
      }

      while (currentPage <= totalPages) {
        await fetchPage(currentPage)
        currentPage += 1
      }

      this.loggers = allLoggers
    },
    onLoggerSelect(val) {
      ApiService.get(val.replace('/api/v1', '')).then(({ data }) => {
        this.deviceConfiguration.imei = data.imei
      })
    },

    toggleIsDemo(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj bude demo?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude demo?')
        if (confirmation === false) return
      }

      this.device.isDemoDevice = !checked
    },
    toggleIsPrizeDevice(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj ne bude prize?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude prize?')
        if (confirmation === false) return
      }

      this.device.isPrizeDevice = !checked
    },

    toggleIsMonsterDevice(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj ne bude monster?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude monster?')
        if (confirmation === false) return
      }

      this.device.isMonsterDevice = !checked
    },

    toggleIsConvertedToEuro(checked) {
      this.device.isConvertedToEuro = !checked
    },

    toggleIsActive(checked) {
      if (checked) {
        let confirmation = confirm('Želite deaktivirati ovaj uređaj?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite aktivirati ovaj uređaj?')
        if (confirmation === false) return
      }

      this.device.isActive = !checked
    },

    toggleServiceMode(checked) {
      this.device.serviceMode = !checked
    },

    toggleFiscal(checked) {
      this.device.fiscal = !checked
    },

    toggleHaveMark(checked) {
      this.device.haveMark = !checked
    },

    toggleIsAcceptor(checked) {
      this.device.isAcceptor = !checked
    },

    toggleIsSaldoReducer(checked) {
      this.device.isSaldoReducer = !checked
    },

    //
    toggleDoubleSMS() {
      this.deviceConfiguration.configuration['start-game'] = {
        outputs: [9, 10],
        game_duration: 50,
        num_game_starts: 2,
        start_game_pulse_duration: 100,
      }
      this.doubleSMS = !this.doubleSMS
    },

    //
    updateDeviceConfiguration() {
      ApiService.update(
        'device_configurations',
        this.deviceConfigurationId,
        this.deviceConfiguration
      ).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Konfiguracija izmjenjena',
        })
      })
    },
    viewDeviceConfiguration() {
      ApiService.get(
        'device_configurations',
        `?imei=${this.device.logger.imei}&order[createdAt]=desc`
      ).then(({ data }) => {
        this.allConfigurations = [...data['hydra:member']]
        this.isDeviceConfigurationModalShow =
          !this.isDeviceConfigurationModalShow
        this.$root.$emit('bv::show::modal', 'device_configuration_modal')
      })
    },

    // show modal callback
    showModal() {
      this.$bvModal.show('add-tag-modal')
      setTimeout(() => {
        document.getElementById('tagName').focus()
      }, 0)
    },

    // submit
    submit: function (e) {
      e.preventDefault()

      const payload = {
        ...this.device,
        saldoMinimum: parseFloat(this.device.saldoMinimum),
        tokenMinimum: parseFloat(this.device.tokenMinimum),
        token: parseFloat(this.device.token),
        tags: this.device.tags.map((tag) => {
          if (tag['@id']) {
            return tag['@id']
          }
          return tag
        }),
      }

      ApiService.update('devices', this.$route.params.id, payload)
        .then(() => {
          this.$router.push({ name: 'devices-list' })
        })
        .catch(() => {})
    },

    // deviceParser
    deviceParser(data) {
      if (data && data[0]) {
        if (data[0].type === 'ping') return 'PING'

        if (data[0].type === 'pay_sms') return 'SMS'

        if (data[0].type === 'on') return 'ON'

        if (data[0].type === 'empty') return 'EMPTY'
      }

      if (data.length >= 1) {
        // parallel
        if (this.deviceConfiguration.configuration.hasOwnProperty('parallel')) {
          let sum = 0
          for (let i = 0; i < data.length; i++) {
            const lineValue = data[i].line
            const configInputValues = parseInt(
              this.deviceConfiguration.configuration.parallel.input_values[
                lineValue
              ]
            )

            if (
              this.deviceConfiguration.configuration.parallel.input_values.hasOwnProperty(
                lineValue
              )
            ) {
              if (configInputValues === 0) {
                sum += 0.5
              } else {
                sum += configInputValues
              }
            }
          }

          return sum + ' €'
        }

        // pulse
        if (this.deviceConfiguration.configuration.hasOwnProperty('pulse')) {
          let sum = 0
          for (let i = 0; i < data.length; i++) {
            const lineValue = data[i].line
            if (this.deviceConfiguration.configuration.pulse[lineValue]) {
              const configInputValues = parseInt(
                this.deviceConfiguration.configuration.pulse[lineValue].value
              )
              sum += configInputValues
            }
          }
          return sum + ' €'
        }

        // sci
        if (this.deviceConfiguration.configuration.hasOwnProperty('sci')) {
          let sum = 0
          for (let i = 0; i < data.length; i++) {
            if (data[i].payload === 'C4D0E0F081') {
              sum += 0.5
            }
            if (data[i].payload === 'C3D0E0F082') {
              sum += 1.0
            }
            if (data[i].payload === 'C2D0E0F083') {
              sum += 2.0
            }
            if (data[i].payload === 'C1D0E0F084') {
              sum += 5.0
            }
          }
          return sum + ' €'
        }

        // exec
        if (this.deviceConfiguration.configuration.hasOwnProperty('exec')) {
          let sum = 0

          if (data[0].type === 'pay_exec') {
            const configInputValues = parseInt(
              this.deviceConfiguration.configuration.exec.output_values[
                data[0].payload
              ]
            )
            sum += configInputValues
          }
          return sum + ' €'
        }
      }
    },

    loadMore() {
      this.page++
      this.isLoaded = false

      ApiService.get(
        '/messages?imei=' +
          this.device.logger.imei +
          '&order[createdAt]=desc&page=' +
          this.page
      ).then(({ data }) => {
        this.messages = this.messages.concat(data['hydra:member'])
        this.isLoaded = true
      })
    },

    onSelectMessage(index) {
      this.selectedMessage = this.messages[index]
    },

    refresh() {
      // messages
      ApiService.get(
        '/messages?imei=' +
          this.device.logger.imei +
          '&order[createdAt]=desc&page=1'
      ).then(({ data }) => {
        this.messages = [...data['hydra:member']]
        this.isMessagesLoaded = true
      })
    },

    addArticles() {
      ApiService.get('/articles').then(({ data }) => {
        this.articles = data['hydra:member']
      })
    },

    selectArticle() {},

    // region
    addTag() {
      ApiService.post('/tags', {
        name: this.newTag,
        company: this.currentUserCompany,
      }).then(({ data }) => {
        this.device.tags.push(data)
        this.$bvModal.hide('add-tag-modal')
        this.newTag = ''

        ApiService.get(
          'tags',
          '?pagination=false&company=' +
            this.currentUserCompany.replace('/api/v1/companies/', '')
        ).then(({ data }) => {
          this.tags = [...data['hydra:member']]
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Tag uspješno dodan',
          })
        })
      })
    },

    deselectLogger() {
      let confirmation = confirm(
        'Do you want to deselect device from the logger?'
      )
      if (confirmation === false) return

      this.device.logger = null
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--logger-select,
  &--location-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
.jsoneditor-modes,
.jsoneditor-poweredBy {
  display: none !important;
}

div.jsoneditor {
  border-radius: 4px;
}
</style>
